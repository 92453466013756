$light-color: #fff;
$dark-grey: #555;
$light-grey: #F3F3F3;
$dark-color: #000;
$header-color: #072069;
$light-text-color: $light-color;
$footer-color: #140E38;
$navy-dark-color: $header-color;
$body-background-color: #FFC400;
$light-border-color: #4A446A;
$dark-blue: #326fac;
$light-blue: #345E92;

// --------- new color scheme naming
$white: #FFF;
$black: #000;

$gray-50: #F2F2F2;
$gray-100: #E4E4E4;
$gray-200: #AEAEAE;
$gray-600: #646464;
$gray-800: #242424;
$gray-900: #1A1A1A;

$blue-100: #EEF3FF;
$blue-400: #0079C2;
$blue-500: #2C5DE5;

$dark-blue-500: #455B7B;

@mixin title_font {
  font-family: "Montserrat", "Open Sans", sans-serif;
}

@mixin gradient($from-color, $to-color) {
  background-color: mix($from-color, $to-color); /* Fallback */
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from-color), to($to-color));
  background-image: -webkit-linear-gradient(left, $from-color, $to-color);
  background-image:    -moz-linear-gradient(left, $from-color, $to-color);
  background-image:     -ms-linear-gradient(left, $from-color, $to-color);
  background-image:      -o-linear-gradient(left, $from-color, $to-color);
  -ms-filter:	"progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$from-color}', EndColorStr='#{$to-color}')";
}

@mixin title_divider {
  height: .3rem;
  width: 10rem;
  border-radius: .5rem;
  @include gradient(#307ec3, #345F95);
}

@mixin logo_colors {
  filter: brightness(0) invert(1);
}

.bg-cover {
  background-size: cover;
}

.text-black {
  color: $black;
}
