@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@dmuy/toast/dist/mdtoast.css';
@import '../stylesheets/variables';

@mixin filter-to-teal {
  filter: invert(47%) sepia(32%) saturate(5061%) hue-rotate(154deg) brightness(102%) contrast(94%);
}

@mixin filter-to-white {
  filter: invert(100%) sepia(98%) saturate(0%) hue-rotate(327deg) brightness(104%) contrast(102%);
}

@mixin filter-to-gray {
  filter: invert(74%) sepia(7%) saturate(450%) hue-rotate(179deg) brightness(89%) contrast(81%);
}

body {
  @apply font-body;
}

@layer components {
  .btn {
    @apply inline-block my-2 bg-teal-500 transition duration-150 ease-in-out hover:bg-teal-700 text-white px-4 py-2.5 text-base cursor-pointer rounded;
  }

  .btn-outlined {
    @apply inline-block my-2 bg-white transition duration-150 ease-in-out hover:border-teal-700 hover:text-teal-700 rounded border border-teal-500 text-teal-500 px-4 py-3 leading-5 text-base cursor-pointer;
  }

  .btn-sections {
    @apply my-0 mr-5 h-11 py-2.5;
  }

  .md-label-focus {
    color: #747474;
    transform: translate(0, -58px) scale(0.75);
    transform-origin: top left;
  }
}

.text-2xs {
  font-size: 0.6rem;
  line-height: 0.7rem;
}

.md-input-main {
  @apply text-xl w-full;
}

.md-input-box, .md-multiselect-box {
  @apply relative font-sans text-xl w-full;
  font-size: 1.25rem;
}

.md-input {
  @apply w-full outline-none shadow border border-solid;
  height: 50px;
  border-color: #eaeaea;
}

textarea.md-input {
  height: auto;
}

.md-input:focus {
  @apply border-0 ring-0
}

.md-multiselect, .md-multiselect:focus {
  @extend .md-input;
  @apply pb-3;
  border-width: 1px;
  border-top: 0.75rem solid #fff;
  border-radius: 2px;
  padding-top: 0;
  height: inherit;
}

.md-label {
  @apply absolute pointer-events-none block bg-white ml-1 px-2;
  color: #747474;
  transform-origin: top left;
  transform: translate(0, -35px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.md-multiselect-label {
  @apply absolute pointer-events-none block bg-white ml-1 px-2;
  color: #747474;
  transform-origin: top left;
  transform: translate(0, -116px) scale(0.75);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
  transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.md-input:focus + .md-label, .md-input:not(:placeholder-shown) + .md-label {
  @apply md-label-focus;
}

.md-input {
  &.is-invalid {
    @apply border-2 border-solid border-red-500;
  }
}

.md-label, .md-multiselect-label {
  &.is-invalid {
    @apply text-red-500 #{!important};
  }
}

.md-vertical-collection {
  .is-invalid {
    @apply text-red-500 border border-red-500 #{!important};
  }
}

.icon {
  &-white {
    @include filter-to-white;
  }

  &-teal {
    @include filter-to-teal;
  }
}

@variants group-hover, hover {
  .icon-hover-teal {
    @include filter-to-teal;
  }
}

.invalid-feedback {
  @apply text-red-500;
  background: url('../images/alert_form.svg') no-repeat top left;
  background-size: 30px 15px;
  padding-left: 30px;
}

.for-whom {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.mdtoast {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  font-size: 20px;

  .mdt-action {
    cursor: pointer;
    background: url('../images/close_icon.svg') no-repeat center center;
    background-size: contain;
    width: 10px;
    height: 10px;
    padding: 5px;
    min-height: auto;
    color: #fff;
    margin-top: -20px;
    margin-right: -10px;
    font-weight: normal;
  }
}

.colon {
  @apply text-xl px-1 pb-8;
  @apply lg:text-3xl;
}

.days, .hours, .minutes, .seconds {
  @apply text-center inline-block text-center font-bold;
  @apply text-4xl w-16;
  @apply lg:text-5xl lg:w-22;
}

.w {
  &-22 {
    width: 5.5rem;
  }
}

.-z-1 {
  z-index: -1;
}

label a {
  text-decoration: underline
}

.sponsor--logo {
  max-height: 40px;
  width: auto;
  max-width: 100%;

  &.medium {
    max-height: 60px;
  }

  &.big {
    max-height: 80px;
  }
}

.pagination {
  @apply text-lg text-teal-500;

  .prev {
    position: relative;
    padding-left: 50px;
    z-index: 1;
  }

  .prev::before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 10px;
    content: " ";
    background: no-repeat right 8px;
    background-size: 28px;
    background-image: url('../images/right-arrow.svg');
    transform: rotate(180deg);
    z-index: -1;
    @include filter-to-teal;
  }

  a {
    @apply text-teal-500;
  }

  .next {
    position: relative;
    padding-right: 50px;
    z-index: 1;
  }

  .next::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 10px;
    background: no-repeat right 8px;
    background-size: 28px;
    background-image: url('../images/right-arrow.svg');
    color: inherit;
    z-index: -1;
    @include filter-to-teal;
  }

  .disabled {
    @apply text-gray-400 #{!important};

    &.prev, &.next {
      @apply border-gray-400 #{!important};
    }

    &.prev::before, &.next::after {
      @include filter-to-gray;
    }
  }

  .pages {
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
}

.toggle-password-wrapper {
  position: relative;

  input {
    width: 100%;
    z-index: 1;
  }

  i {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(90%);
    z-index: 2;
    margin: 0 10px;
    color: #888;
    cursor: pointer;
  }
}

.congress-lasting {
  padding-left: 1rem;
  position: relative;

  &:before {
    content: '';
    background-color: #02afbe;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 5px;
    animation: dot-on-air 1s ease-in infinite alternate;
  }
}

.block_company {
  position: relative;
}

.search_results {
  position: absolute;
  top: 50px;
  width: 100%;
  background: white;

  ul {
    list-style-type: none;
    padding: 0;
    border: 1px solid $gray-100;

    li {
      padding: 0.6rem;
      cursor: pointer;

      &:hover {
        background-color: $gray-100;
      }
    }
  }
}

@keyframes dot-on-air {
  from {
    background-color: #02afbe;
  }

  to {
    background-color: transparent;
  }
}

@layer base {
  .footer a {
    &:hover {
      @apply text-teal-100;
    }
  }
}

#curtain {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.5);
}

.header {
  &-small {
    @apply text-2xl;
  }

  &-medium {
    @apply text-3xl;
  }

  &-large {
    @apply text-4xl;
  }
}

.trix-content {
  & * {
    font-family: Inter, sans-serif;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul, ol {
    padding-left: 3rem;
  }
}

.attachment {
  &__caption {
    display: none;
  }
}

.flex-equal {
  flex: 1 1 20%;
}

#notice-container {
  font-family: Inter;
  font-size: 15px;
  .btn {
    border-radius: 2px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  #notice-container,
  #notice-container .btn {
    font-size: 13px;
  }
}
